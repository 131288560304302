export const PredefinedFeedbackTypes = {
  MANUAL_ASSIGNMENT: 'FeedbackTypes.MANUAL_ASSIGNMENT',
  MANUAL_UNASSIGNMENT: 'FeedbackTypes.MANUAL_UNASSIGNMENT',
  ORDER_CANCELLATION: 'FeedbackTypes.ORDER_CANCELLATION',
  IS_MANUAL: 'FeedbackTypes.IS_MANUAL'
}

export interface PredefinedReasonInterface {
  id: number,
  description: string,
  feedbackType: string,
  isActive: boolean
}
